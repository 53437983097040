* {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

@font-face {
    font-family: "Roboto";
    src: url(./Roboto-Black.ttf);
}


/* width */
::-webkit-scrollbar {
    width: 0px;
}


.title {
    color: white; 
    font-size: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-family: "Roboto";
    text-align: center;
}

.titlealt {
    font-size: 30px;
}

.contactbutton {
    height: 60px;
    width: 120px;
    font-size: 20px;
}

.p-button{
    border: 0 solid;
}

.p-button:enabled:hover{
    background-color: #b57a36;
    border-color: #b57a36;
    box-shadow: 0 0 0 0.2rem transparent;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem transparent;
  }

  .mouse-wrapper {
    font-family: "Roboto";
    text-decoration: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFF;
    font-size: 14px;
    display: block;
    max-width: 120px;
    margin: auto;
    text-align: center;
  }
  .mouse-wrapper:hover {
    color: #FFF;
  }
  
  .mouse {
    border: solid 2px #FFF;
    border-radius: 16px;
    display: block;
    margin: auto;
    margin-top: 10px;
    height: 26px;
    position: relative;
    width: 20px;
  }
  .mouse .wheel {
    background: #FFF;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: 4px;
    -webkit-animation: ani-mouse 2s linear infinite;
    -moz-animation: ani-mouse 2s linear infinite;
    animation: ani-mouse 2s linear infinite;
  }
  
  .circle {
    border-radius: 100%;
  }


  .aboutsection {
    color: white;
    font-family: "Roboto";
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
  }

  .aboutsection img {
    width: 60%;
  }

  .title1 {
    font-family: "Roboto";
    font-size: 45px;
    color: white;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .title2 {
    font-family: "Roboto";
    font-size: 40px;
    color: white;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .title3 {
    font-family: "Roboto";
    font-size: 40px;
    color: white;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .paragraph {
    font-family: "Roboto";
    font-size: 35px;
    color: white;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .partnersinfo {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
  }

  .contactform {
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(43, 43, 43, 0.613);
    width: 50%;
    border-radius: 10px;
  }

  .contactinput {
    font-family: "Roboto";
    color: white;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    background-color: transparent;
    display: inline-block;
    border: none;
    border-bottom: 2px solid white;
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    resize: none;
  }

  .contactinput::placeholder {
    color: rgb(179, 179, 179);
  }

  .contactsubmit {
    width: 80%;
    margin-left: 50%;
    transform: translateX(-50%);
  }

@media screen and (max-width: 768px) {
  .title1 {
    width: 100%;
    font-family: "Roboto";
    font-size: 40px;
    text-align: center;
    color: white;
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

    .title2 {
      top: 35%;
    }

    .title3{
      top: 15%;
    }

    .contactform {
      width: 95%;
    }

    .paragraph {
      width: 90%;
      font-size: 25px;
      text-align: center;
      top: 40%;
    }

    .aboutsection {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      width: 90%;
      height: 100%;
    }

    .aboutsection img {
      display: none;
    }

    .title2 {
      width: 100%;
      text-align: center;
    }

    .aboutinfo{
      text-align: center;
      width: 100%;
    }

    .aboutchild {
      display: inline-block;
    }

    .title {
        font-size: 55px;
    }

    .titlealt {
        font-size: 20px;
    }

    .contactbutton {
        height: 50px;
        width: 100px;
        font-size: 15px;
    }
}

@-webkit-keyframes ani-mouse {
    0% {
      top: 29%;
    }
    15% {
      top: 50%;
    }
    50% {
      top: 50%;
    }
    100% {
      top: 29%;
    }
  }
  @-moz-keyframes ani-mouse {
    0% {
      top: 29%;
    }
    15% {
      top: 50%;
    }
    50% {
      top: 50%;
    }
    100% {
      top: 29%;
    }
  }
  @keyframes ani-mouse {
    0% {
      top: 29%;
    }
    15% {
      top: 50%;
    }
    50% {
      top: 50%;
    }
    100% {
      top: 29%;
    }
  }

  #background {
    background: 100% radial-gradient(ellipse at bottom, rgba(20,20,20,.8) 10% 10%, rgba(20,20,20,1)), center / 400% no-repeat linear-gradient(-45deg, #c27509, #5d05d8, #fc0000f0, rgb(255, 217, 0));
    animation: about-section-anim 15s ease infinite;
  }

  

  @keyframes about-section-anim {
    0% { background-position: 0 50%; }
    20% { background-position: 20% 50%; }
    40% { background-position: 40% 50%; }
    60% { background-position: 60% 50%; }
    80% { background-position: 80% 50%; }
    100% { background-position: 0 50%; }
  }