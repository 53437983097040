.navbarmobile {
    left: 0;
    top: 0;
    width: 100vw;
    position: fixed;
    display: none;
    z-index: 1;
    backdrop-filter: blur(5px);
}

.navbarpc {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 1;
    backdrop-filter: blur(5px);
}

.navbarbutton{
    background-color: transparent;
    border-color: #b57a36;
}

.navbarbutton:enabled:hover{
    background-color: #b57a36;
    border-color: #b57a36;
    box-shadow: 0 0 0 0.2rem transparent;
}

#popup_menu_right {
    background-color: black;
}
.p-menu {
    color: white;
}
.p-menu .p-menuitem-link{
    color: white;
}
.p-menu .p-menuitem-link .p-menuitem-text {
    color: white;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #b57a36;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #b57a36;
}
.p-menu .p-submenu-header{
    color: white;
    background: black;
}

.alink {
    color: white;
    transition: all .5s;
}

.alink:hover {
    color: #b57a36;
}

@media screen and (max-width: 768px) {
    .navbarpc {
        display: none;
    }

    .navbarmobile {
        display: block;
    }
}